import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const projects = [
  {
    name: "SaveTabs",
    description:
      "Simply click on the icon or press Alt+Shift+D to bookmark all open tabs.",
    links: [
      {
        label: "Source",
        url: "https://github.com/wasdjkl/save-tabs",
      },
      {
        label: "Edge Addons",
        url: "https://microsoftedge.microsoft.com/addons/detail/save-tabs/gmafmjfnefglnbjmobecgjglinaadjbg",
      },
    ],
  },
  {
    name: "wasdjkl.com",
    description: "The source of this website. Made with ❤, using Gatsby.",
    links: [
      {
        label: "Source",
        url: "https://github.com/wasdjkl/wasdjkl.com",
      },
      {
        label: "Website",
        url: "https://www.wasdjkl.com/",
      },
    ],
  },
  {
    name: "Bookmarks Tool",
    description: "Bookmarks Organizer Tool",
    links: [
      {
        label: "Source",
        url: "https://github.com/wasdjkl/bookmarks-tool",
      },
      {
        label: "Demo",
        url: "https://bookmarks-tool.vercel.app/",
      },
    ],
  },
  {
    name: "Watermark Crack",
    description: "去除某软件全屏水印",
    links: [
      {
        label: "Write-up",
        url: "/posts/crack-fullscreen-watermark",
      },
      {
        label: "Source",
        url: "https://github.com/wasdjkl/watermark-crack",
      },
      {
        label: "Download",
        url: "https://github.com/wasdjkl/watermark-crack/releases",
      },
    ],
  },
  {
    name: "Copy File to Android",
    description: "Copy arbitrary directories and files to any location in the device using the push command",
    links: [
      {
        label: "Source",
        url: "https://github.com/wasdjkl/copy-file-to-android-device",
      },
      {
        label: "JetBrains Marketplace",
        url: "https://plugins.jetbrains.com/plugin/18407-copy-file-to-android-device------",
      },
    ],
  },
];

const ProjectLink = ({ link }): JSX.Element => {
  return (
    <a
      className="p-1 bg-white rounded-sm text-sm text-gray-600 border-2 hover:cursor-pointer hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200"
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="px-0.5">{link.label}</span>
    </a>
  );
};

const ProjectCard = ({ project }): JSX.Element => {
  return (
    <div className="w-full p-4 bg-gray-50 rounded-xl text-center border-2">
      <h2>{project.name}</h2>
      <div className="my-2">{project.description}</div>
      <div className="flex flex-wrap gap-5 justify-center">
        {project.links.map((link) => {
          return <ProjectLink key={link.url} link={link} />;
        })}
      </div>
    </div>
  );
};

const ProjectsPage = () => {
  return (
    <Layout>
      <SEO title="Projects" />
      <header className="my-10 text-center">
        <h1>Projects</h1>
        <div>
            A few highlights of my open-source projects. View them all on{" "}
          <a
            href="https://github.com/wasdjkl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
          .
        </div>
      </header>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {projects.map((project) => {
          return <ProjectCard key={project.name} project={project} />;
        })}
      </div>
    </Layout>
  );
};

export default ProjectsPage;
